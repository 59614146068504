import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '../components/layout'
import Button from '../components/Button'
import Img from 'gatsby-image'


export const query = graphql`
    query {
        imageHero: file(relativePath: { eq: "images/acton-hero.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        imageApproach: file(relativePath: { eq: "images/learn-by-doing.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        imageProjects: file(relativePath: { eq: "images/learn-by-doing-2.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        imageCampus: file(relativePath: { eq: "images/campus-alt.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        pageApproach: wordpressPage(slug: { eq: "approach" }) {
            title
            excerpt
            slug
        }
        pageProjects: wordpressPage(slug: { eq: "projects" }) {
            title
            excerpt
            slug
        }
        pageCampus: wordpressPage(slug: { eq: "campus" }) {
            title
            excerpt
            slug
        }
    }
`

export default ({ data }) => {
    console.log(data)
    return (
        <Layout>
            <Helmet>
                <title>Turn Learning Upside Down / Acton Academy Upstate</title>
            </Helmet>
            <main>
                <header
                    css={css`
                        position: relative;
                        padding: 0 0 2em;
                        h1 {
                            position: relative;
                            font-size: 2em;
                            line-height: 1.1;
                            em {
                                font-style: normal;
                            }
                        }
                        @media ( max-width: 48em ) {
                            figure {
                                margin-top: 0;
                                margin-right: -8em;
                                margin-left: -8em;
                            }
                        }
                        @media ( min-width: 48em ) {
                            padding-top: 10em;
                            padding-bottom: 8em;
                            padding-right: 45%;
                            padding-left: 3em;
                            figure {
                                position: absolute;
                                margin: 0;
                                top: 0;
                                width: 60%;
                                left: 52%;
                                bottom: 0;
                                img {
                                    max-width: 100%;
                                }
                            }
                            h1 {
                                font-size: 2.875em;
                                em {
                                    display: block;
                                }
                            }
                            p {
                                font-size: 1.25em;
                                margin-top: 0;
                                max-width: 30em;
                            }
                        }
                    `}
                >
                    <figure>
                        <Img fluid={data.imageHero.childImageSharp.fluid} alt="" />
                    </figure>
                    <h1>Launch your Hero’s Journey: <em>Find a calling; change the world.</em></h1>
                    <p>The Acton Academy mission is to inspire each child and parent who enters our doors to find a calling that will change the world.</p>
                    <nav
                        css={css`
                            display: flex;
                            a:not(:last-child) {
                                margin-right: 1em;
                            }
                        `}
                    >
                        <Link to="/mission"><Button>Our Mission</Button></Link>
                        <Link to="/a-day-at-acton"><Button>A Day at Acton</Button></Link>
                    </nav>
                </header>
                <div
                css={css`
                    position: relative;
                    background-color: #fff;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        width: 100vw;
                        left: 50%;
                        margin-left: -50vw;
                        background-color: inherit;
                    }
                    section {
                        position: relative;
                        padding: 1.5em 0;
                        h2 {
                            font-size: 1.5em;
                            margin: 0;
                            line-height: 1.2;
                        }
                        p {
                            margin: 0.5em 0 1em;
                        }
                    }
                    @media ( max-width: 48em ) {
                        section {
                            padding-top: 2.5em;
                            padding-bottom: 2.5em;
                        }
                        section:nth-of-type(odd) {
                            padding-right: 35%;
                        }
                        section:nth-of-type(even) {
                            padding-left: 38%;
                        }
                        section figure {
                            position: absolute;
                            top: 50%;
                            width: 70%;
                            transform: translate3d(0,-55%,0);
                        }
                        section:nth-of-type(odd) figure {
                            left: 60%;
                        }
                        section:nth-of-type(even) figure {
                            right: 60%;
                        }
                        section > div {
                            position: relative;
                        }
                    }
                    @media ( min-width: 48em ) {
                        position: relative;
                        display: grid;
                		grid-template-columns: 15% 33% 6% 33% 15%;
                		grid-template-rows: auto auto;
                		grid-template-areas:
                			". main main main ."
                			". second . third .";
                        section {
                            padding: 2em 0;
                            h2 {
                                font-size: 2em;
                            }
                            p {
                                font-size: 1.125em;
                            }
                        }
                        section:not(:first-child) {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            text-align: center;
                            padding-bottom: 6em;
                            figure {
                                width: 100%;
                                max-width: 30em;
                                margin-right: auto;
                                margin-left: auto;
                            }
                            & > div {
                                display: flex;
                                flex-direction: column;
                                flex-grow: 1;
                                a {
                                    margin-top: auto;
                                }
                            }
                        }
                        section:nth-child(2) {
                            grid-area: second;
                    		place-self: stretch;
                        }
                        section:nth-child(3) {
                            grid-area: third;
                    		place-self: stretch;
                        }
                    }
                `}>
                    <section
                        css={css`
                            @media ( min-width: 48em ) {
                        		grid-area: main;
                        		place-self: stretch;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                figure {
                                    width: 40%;
                                    margin-right: 5%;
                                }
                                & > div {
                                    width: 45%;
                                }
                            }

                        `}
                    >
                        <figure>
                            <Img fluid={data.imageApproach.childImageSharp.fluid} alt="" />
                        </figure>
                        <div>
                            <h2>{data.pageApproach.title}</h2>
                            <div dangerouslySetInnerHTML={{ __html: data.pageApproach.excerpt }} />
                            <Link to={`/${data.pageApproach.slug}`}><Button>Our Approach</Button></Link>
                        </div>
                    </section>
                    <section>
                        <figure
                            css={css`
                                @media ( min-width: 48em ) {
                                    max-width: 21.95em !important;
                                }
                            `}
                        >
                            <Img fluid={data.imageProjects.childImageSharp.fluid} alt="" />
                        </figure>
                        <div>
                            <h2>{data.pageProjects.title}</h2>
                            <div dangerouslySetInnerHTML={{ __html: data.pageProjects.excerpt }} />
                            <Link to={`/${data.pageProjects.slug}`}><Button>Learner Projects</Button></Link>
                        </div>
                    </section>
                    <section>
                        <figure>
                            <Img fluid={data.imageCampus.childImageSharp.fluid} alt="" />
                        </figure>
                        <div>
                            <h2>Located at Two Fox Farms</h2>
                            <div dangerouslySetInnerHTML={{ __html: data.pageCampus.excerpt }} />
                            <Link to={`/${data.pageCampus.slug}`}><Button>Campus</Button></Link>
                        </div>
                    </section>
                </div>
            </main>
        </Layout>
    )
}
